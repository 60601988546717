<template>
    <!-- 下部banner -->
    <div class="course-banner-bottom">
        <h2 class="start-h2-wrap">
            免费专业的SPSSPRO<br />
            让数据分析更简单
        </h2>
        <wj-button type="primary" @click="goToDataAnaly">立即使用</wj-button>
    </div>
</template>

<script>
import { Button } from '@wenjuan/ui';
export default {
    methods: {
        // 已登录 直接去分析页
        goToDataAnaly() {
            window.open('https://www.spsspro.com/');
        },
    },
    components: {
        'wj-button': Button,
    },
};
</script>

<style lang="scss" scoped>
// 下部banner
.course-banner-bottom {
    min-height: 240px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(~@/assets/images/home-freeuse-bg.png);
    @media (min-width: 641px) and (max-width: 1366px) {
        background-image: url(~@/assets/images/home-freeuse-bg-1366.png);
    }
    @media (max-width: 640px) {
        min-height: 230px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .start-h2-wrap {
        font-size: 32px;
        font-family: SourceHanSansCN, SourceHanSansCN-Bold;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 48px;
        margin: 0;
        // padding: 30px 0;
        @media (max-width: 640px) {
            font-size: 28px;
            font-family: PingFang SC;
            line-height: 40px;
        }
    }
    .wj-button--primary {
        width: 128px;
        height: 40px;
        border-radius: 4px;
        background-color: #fff;
        border-color: #fff;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 600;
        color: #1a78ff;
        @media (max-width: 640px) {
            font-size: 16px;
        }
    }
}
</style>
